var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-results" },
    [
      _c(
        "div",
        {
          staticClass: "team-results__content",
          class: {
            "team-results__content--scrollable": _vm.isFactMatchRelatedResult,
            "team-results__content--enlarged": _vm.enlargedUI,
          },
        },
        [
          _vm.isFactMatchRelatedResult
            ? _vm._l(_vm.teamsSortedByTotalScore, function (team) {
                var _obj
                return _c(
                  "div",
                  { key: team.id },
                  [
                    _c(
                      "transition",
                      {
                        attrs: {
                          name: "team-results__tag-transition",
                          mode: "out-in",
                        },
                      },
                      [
                        team.showTag
                          ? _c("TeamTag", {
                              key: team.id + "-tag",
                              staticClass: "team-results__tag",
                              class: {
                                "team-results__tag--active":
                                  team.id === _vm.highlightedTeamID,
                              },
                              attrs: { team: team, mode: "play" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("TeamFactMatchCard", {
                      staticClass: "team-results__card",
                      class:
                        ((_obj = {}),
                        (_obj["team-results__card--clickable"] =
                          _vm.isViewerHostLike),
                        _obj),
                      attrs: {
                        pts: _vm.getMatchScore(team.id),
                        teamName: team.name,
                        state: team.flippedState,
                        teamIcon: team.icon,
                      },
                      nativeOn: {
                        click: [
                          function ($event) {
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            return _vm.onTeamCardClick(team)
                          },
                          function ($event) {
                            if (!$event.altKey) {
                              return null
                            }
                            return _vm.onTeamCardClickAlt()
                          },
                        ],
                      },
                    }),
                  ],
                  1
                )
              })
            : _vm.showLocationStandings
            ? _vm._l(
                _vm.locationsSortedByTotalScore,
                function (location, index) {
                  var _obj
                  return _c(
                    "div",
                    { key: location.id },
                    [
                      _c("TeamSpecificsCard", {
                        staticClass: "team-results__card",
                        class:
                          ((_obj = {}),
                          (_obj["team-results__card--clickable"] =
                            _vm.isViewerHostLike),
                          _obj),
                        attrs: {
                          info: location.specifics,
                          teamTotal: location.totalScore,
                          teamName: location.name,
                          position: index + 1,
                          state: location.state,
                          current: location.current,
                          numeric: true,
                        },
                        nativeOn: {
                          click: [
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              ) {
                                return null
                              }
                              return _vm.onLocationCardClick(location.id)
                            },
                            function ($event) {
                              if (!$event.altKey) {
                                return null
                              }
                              return _vm.onLocationCardClickAlt()
                            },
                          ],
                        },
                      }),
                    ],
                    1
                  )
                }
              )
            : _vm._l(_vm.teamsSortedByTotalScore, function (team, index) {
                var _obj, _obj$1
                return _c(
                  "div",
                  { key: team.id },
                  [
                    _c(
                      "transition",
                      {
                        attrs: {
                          name: "team-results__tag-transition",
                          mode: "out-in",
                        },
                      },
                      [
                        team.showTag
                          ? _c("TeamTag", {
                              key: team.id + "-tag",
                              staticClass: "team-results__tag",
                              class: {
                                "team-results__tag--active":
                                  team.id === _vm.highlightedTeamID,
                              },
                              attrs: {
                                team: team,
                                pinnable: team.pinnable,
                                score: team.score,
                                mode: "play",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.isJeopardAiActivity
                      ? _c("JeopardAiTeamResultCard", {
                          staticClass: "team-results__card",
                          class:
                            ((_obj = {}),
                            (_obj["team-results__card--clickable"] =
                              _vm.isViewerHostLike),
                            _obj),
                          attrs: {
                            team: team,
                            position: index + 1,
                            flipped: team.flippedState === "results",
                            current: team.id === _vm.highlightedTeamID,
                          },
                          nativeOn: {
                            click: [
                              function ($event) {
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                ) {
                                  return null
                                }
                                return _vm.onTeamCardClick(team)
                              },
                              function ($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                return _vm.onTeamCardClickAlt()
                              },
                            ],
                          },
                        })
                      : _c("TeamSpecificsCard", {
                          staticClass: "team-results__card",
                          class:
                            ((_obj$1 = {}),
                            (_obj$1["team-results__card--clickable"] =
                              _vm.isViewerHostLike),
                            _obj$1),
                          attrs: {
                            info: _vm.getTeamSpecificsCardData(team),
                            teamTotal: team.totalScore,
                            teamName: team.name,
                            position: index + 1,
                            state: team.flippedState,
                            teamIcon: team.icon,
                            numeric: true,
                            current: team.id === _vm.highlightedTeamID,
                            postfix: team.postfix,
                            prefix: team.prefix,
                            details: team.details,
                          },
                          nativeOn: {
                            click: [
                              function ($event) {
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                ) {
                                  return null
                                }
                                return _vm.onTeamCardClick(team)
                              },
                              function ($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                return _vm.onTeamCardClickAlt()
                              },
                            ],
                          },
                        }),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _vm.isViewerHostLike && _vm.hasHybridLocations
        ? _c("WeveToggle", {
            staticClass: "team-results__location-toggle",
            attrs: { label: "Location Standings" },
            model: {
              value: _vm.showLocationStandings,
              callback: function ($$v) {
                _vm.showLocationStandings = $$v
              },
              expression: "showLocationStandings",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }