
















































































































// @ts-nocheck
import { mapActions, mapGetters } from "vuex"

import { db } from "@/firebase"

import { GameEvent as RoomEvent } from "@/event"
import { Mode, Mission as MissionType } from "@shared/enums"
import Team from "@shared/Team"
import Collection from "@shared/Collection"
import MissionCollection from "@shared/MissionCollection"

import TeamSpecificsCard from "@/components/GroupTeams/Common/TeamSpecificsCard"
import TeamFactMatchCard from "@/components/GroupTeams/Common/TeamFactMatchCard"

import TeamTag from "@/components/GroupTeams/Common/TeamTag"
import { WeveToggle } from "@weve/ui"
import useEnlargedUI from "@/use/useEnlargedUI"

import Vue from "vue"

const FACT_MATCH_LILKE_MISSION_TYPES = [
  MissionType.OrderTheCards,
  MissionType.MatchGame,
  MissionType.FactMatch,
  MissionType.VideoTeam
]

const CREATIVE_MISSION_TYPES = [
  MissionType.Giphy,
  MissionType.Photo,
  MissionType.TakePhoto,
  MissionType.TeamName,
  MissionType.URL,
  MissionType.DrawingEachTeam,
  MissionType.DrawingPictionary
]

const TRIVIA_MISSION_TYPES = [
  MissionType.Text,
  MissionType.MultipleChoice,
  MissionType.BuzzIn,
  MissionType.Categories
]

export default Vue.extend({
  name: "TeamResults",
  components: {
    TeamFactMatchCard,
    TeamSpecificsCard,
    JeopardAiTeamResultCard: () =>
      import("@/components/GroupTeams/Common/JeopardAiTeamResultCard"),
    TeamTag,
    WeveToggle
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()

    return {
      enlargedUI
    }
  },
  data() {
    return {
      votes: [],
      showLocationStandingsValue: false,
      standing: null
    }
  },
  created() {
    this.$bus.$on(RoomEvent.FlipFactMatchCard, this.onFlipFactMatchCard)
  },
  beforeDestroy() {
    this.$bus.$off(RoomEvent.FlipFactMatchCard, this.onFlipFactMatchCard)
    this.votingRef?.off("value", this.onSnapshot)
    this.showLocationStandignsRef?.off(
      "value",
      this.onShowLocationStandingsSnapshot
    )
    this.standingRef?.off("value", this.onStandingSnapshot)
  },
  watch: {
    standingRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onStandingSnapshot)
        newValue?.on("value", this.onStandingSnapshot)
      },
      immediate: true
    },
    showLocationStandignsRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onShowLocationStandingsSnapshot)
        newValue?.on("value", this.onShowLocationStandingsSnapshot)
      },
      immediate: true
    },
    votingRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onSnapshot)
        newValue?.on("value", this.onSnapshot)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentMode",
      "plays",
      "missionPlaysArray",
      "gameStatus",
      "onlineUsersArray"
    ]),
    ...mapGetters({ mission: "getCurrentMission" }),
    missions() {
      return MissionCollection.normalize(this.$store.getters.missions ?? {})
    },
    hasTeamTag() {
      return Boolean(this.highlightedTeam && !this.isVideoTeamResults)
    },
    locations() {
      if (!this.hasHybridLocations) return []
      return this.$store.state.auth.client?.customInputOptions
        .split(",")
        .map(string => string.trim())
    },
    flippedIdRef() {
      return db
        .auxiliary()
        .ref(`org/${this.orgId}/game/${this.roomId}/gameStatus/flippedTeamID`)
    },
    // TODO
    // use it instead of flippedId
    standingRef() {
      return db.auxiliary().ref(`_room/${this.roomId}/standing`)
    },
    showLocationStandignsRef() {
      return db.auxiliary().ref(`_room/${this.roomId}/showLocationStandigns`)
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    isJeopardAiActivity() {
      return this.mission.behavior === MissionType.JeopardAI
    },
    teams() {
      const isJeopardAiActivity = this.isJeopardAiActivity

      if (this.isViewerHostLike)
        return Team.normalize(this.$store.getters.chats).map(team => ({
          ...team,
          showTag: true,
          pinnable: !isJeopardAiActivity,
          details: !isJeopardAiActivity,
          score: !isJeopardAiActivity,
          postfix: isJeopardAiActivity ? "" : "pts",
          prefix: isJeopardAiActivity ? "$" : undefined,
          totalScore: parseInt(team.totalScore) || 0
        }))

      return Team.normalize(this.$store.getters.chats).map(team => ({
        ...team,
        showTag: team.flippedState === "results",
        pinnable: !isJeopardAiActivity,
        details: !isJeopardAiActivity,
        score: !isJeopardAiActivity,
        postfix: isJeopardAiActivity ? "" : "pts",
        prefix: isJeopardAiActivity ? "$" : undefined,
        totalScore: parseInt(team.totalScore) || 0
      }))
    },
    hasHybridLocations() {
      return this.$store.getters["group/hasHybridLocations"]
    },
    isFactMatchRelatedResult() {
      return (
        FACT_MATCH_LILKE_MISSION_TYPES.includes(this.missionType) &&
        this.mode === Mode.Results
      )
    },
    isVideoTeamResults() {
      return (
        this.missionType === MissionType.VideoTeam && this.mode === Mode.Results
      )
    },
    locationsSortedByTotalScore() {
      const DEFAULT_LOCATION = "default"
      const users = this.onlineUsersArray

      const teamsGroupedByLocation = this.teams
        .filter(team => !Team.isSpecialPurpose(team))
        .map(team => ({
          ...team,
          location:
            users.find(user => user.teamID === team.id && user.identifier)
              ?.identifier ?? DEFAULT_LOCATION
        }))
        .reduce((acc, val) => {
          if (acc[val.location]) {
            acc[val.location].push(val)
          } else {
            acc[val.location] = [val]
          }
          return acc
        }, {})

      const standing = this.standing ?? {}

      return this.locations
        .map(location => {
          const teams = teamsGroupedByLocation[location] ?? []
          const totalScore = teams.reduce((acc, val) => acc + val.totalScore, 0)
          const totalTrivia = teams.reduce(
            (acc, val) => acc + this.totalTrivia(val.id),
            0
          )
          const totalCreatives = teams.reduce(
            (acc, val) => acc + this.totalCreatives(val.id),
            0
          )
          const totalSocial = Math.max(
            0,
            totalScore - totalTrivia - totalCreatives
          )

          const avg = score => Math.max(Math.round(score / teams.length), 0)

          return {
            id: location,
            name: location,
            current: standing.current === location,
            totalScore: avg(totalScore),
            state: standing[location]?.show ? "results" : undefined,
            specifics: [
              {
                title: "Creatives",
                points: avg(totalCreatives)
              },
              {
                title: "Trivia",
                points: avg(totalTrivia)
              },
              {
                title: "Social",
                points: avg(totalSocial)
              },
              {
                title: "Total",
                points: avg(totalScore)
              }
            ]
          }
        })
        .sort(Team.sortByScore)
        .reverse()
    },
    teamsSortedByTotalScore() {
      return this.teams
        .filter(team => !Team.isSpecialPurpose(team))
        .sort(Team.sortByScore)
        .reverse()
    },
    highlightedTeamID() {
      return this.gameStatus?.flippedTeamID
    },
    highlightedTeam() {
      return this.teams.find(team => team.id === this.highlightedTeamID)
    },
    missionType() {
      return this.mission?.behavior
    },
    mode() {
      return this.getCurrentMode
    },
    orgId() {
      return this.$store.getters.orgID
    },
    roomId() {
      return this.$store.getters.gameID
    },
    votingRef() {
      if (!this.mission?.id) return null
      if (!this.isVideoTeamResults) return null

      return db
        .auxiliary()
        .ref(`org/${this.orgId}/game/${this.roomId}/votes`)
        .orderByChild("missionID")
        .equalTo(this.mission?.id)
    },
    showLocationStandings: {
      get() {
        if (!this.hasHybridLocations) return false
        return this.showLocationStandingsValue
      },
      set(value) {
        this.showLocationStandingsValue = value
        this.showLocationStandignsRef.set(value)
        this.flippedIdRef.set("location-standing")
      }
    }
  },
  methods: {
    ...mapActions(["updateGameStatusAny", "updateTeam"]),
    onStandingSnapshot(snapshot) {
      this.standing = snapshot.val()
    },
    onShowLocationStandingsSnapshot(snapshot) {
      this.showLocationStandingsValue = Boolean(snapshot.val())
    },
    onFlipFactMatchCard() {
      // we get the last one team with `flippedState` 'hidden'
      const [team] = this.teamsSortedByTotalScore
        .filter(Team.isHidden)
        .reverse()

      if (team) return this.onTeamCardClick(team)

      // if there is no team we just emit the "next" event
      this.$bus.$emit(RoomEvent.MoveNext)
    },
    getMatchScore(teamID) {
      const isTeamEntity = entity => entity.teamID === teamID

      if (this.isVideoTeamResults) {
        const missionId = this.mission?.id
        const activityId = this.mission?.activityId
        const points = parseInt(this.mission?.points) || 0
        const isCurrentMissionEntity = entity =>
          entity.missionID === missionId || entity.activityId === activityId
        const isTeamVote = vote =>
          isCurrentMissionEntity(vote) && isTeamEntity(vote)
        const votes = this.votes.filter(isTeamVote)
        const score = votes.reduce((acc, val) => acc + (val?.vote || 0), 0)
        return parseInt((score / votes.length / 5) * points) || 0
      }

      const play = this.missionPlaysArray.find(isTeamEntity)

      return parseInt(play?.score) || 0
    },
    getTeamSpecificsCardData(team) {
      const totalTrivia = this.totalTrivia(team.id) || 0
      const totalCreatives = this.totalCreatives(team.id) || 0
      const totalScore = team.totalScore || 0
      const totalSocial = Math.max(0, totalScore - totalTrivia - totalCreatives)

      return [
        {
          title: "Creatives",
          points: totalCreatives
        },
        {
          title: "Trivia",
          points: totalTrivia
        },
        {
          title: "Social",
          points: totalSocial
        },
        {
          title: "Total",
          points: totalScore
        }
      ]
    },
    totalCreatives(teamID) {
      return this.getTotalScoreByTeamAndType(teamID, CREATIVE_MISSION_TYPES)
    },
    totalTrivia(teamID) {
      return this.getTotalScoreByTeamAndType(teamID, TRIVIA_MISSION_TYPES)
    },
    getTotalScoreByTeamAndType(teamID, types) {
      const plays = this.plays
      return this.missions.reduce((score, mission) => {
        if (types.includes(mission.behavior) == false) return score

        return (
          score +
          plays.reduce((score, play) => {
            if (play.missionID !== mission.id) return score
            if (play.teamID !== teamID) return score
            return score + (parseInt(play.score) || 0)
          }, 0)
        )
      }, 0)
    },
    onLocationCardClick(location) {
      if (!this.isViewerHostLike) return
      this.standingRef.child(`${location}/show`).set(true)
      this.standingRef.child("current").set(location)
    },
    onLocationCardClickAlt() {
      if (!this.isViewerHostLike) return
      this.standingRef.set(null)
    },
    onTeamCardClickAlt() {
      if (!this.isViewerHostLike) return
      this.flippedIdRef.set(null)
      this.teams.forEach(team =>
        this.updateTeam({ id: team.id, flippedState: null })
      )
    },
    onTeamCardClick(team) {
      if (!this.isViewerHostLike) return
      this.flippedIdRef.set(team.id)
      this.updateTeam({ id: team.id, flippedState: "results" })
    },
    onSnapshot(snapshot) {
      this.votes = Collection.normalize(snapshot.val() ?? {})
    }
  }
})
