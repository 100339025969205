<template>
  <div
    d-flex
    class="team-specifics-card"
    :class="{ 'team-specifics-card--active': current }"
  >
    <transition name="card-flip-transition" mode="out-in">
      <div
        :key="state"
        class="team-specifics-card__front-side team-specifics-card__card"
        v-if="state === 'results'"
      >
        <div class="team-specifics-card__medal">
          <Medal :variant="position">
            {{ position + positionSuffix }}
          </Medal>
        </div>

        <div
          class="team-specifics-card__score"
          :class="{ 'team-specifics-card__score--presenter': isPresenter }"
          v-if="!client.escapeRoom"
        >
          <template v-if="isBreakdownVisible">
            <v-layout
              row
              v-for="(score, idx) in scores"
              class="team-specifics-card__score-list"
              :key="'team-spec-card-' + idx"
            >
              <v-flex grow class="team-specifics-card__score-title">
                {{ score.title }}
              </v-flex>

              <v-flex shrink class="team-specifics-card__score-points">
                {{ prefix }}{{ score.points }} {{ postfix }}
              </v-flex>
            </v-layout>
          </template>

          <template v-else>
            <div class="team-specifics-card__score-presenter">
              <div class="team-specifics-card__presenter-title">
                {{ total.title }}
              </div>
              <div class="team-specifics-card__presenter-points">
                {{ prefix }}{{ total.points }} {{ postfix }}
              </div>
            </div>
          </template>
        </div>
      </div>

      <div
        :key="state"
        d-flex
        class="team-specifics-card__back-side team-specifics-card__card"
        v-else
      >
        <div class="team-specifics-card__back-side-medal">
          <Medal :variant="position" />
        </div>

        <div class="team-specifics-card__back-side-text">
          <div>{{ positionText }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Medal from "./Medal.vue"
import User from "@shared/User"

export default {
  name: "TeamSpecificsCard",
  components: { Medal },
  props: {
    details: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String
    },
    postfix: {
      type: String,
      default: "pts"
    },
    teamName: {
      type: String,
      default: "Error"
    },
    teamTotal: {
      type: Number,
      default: 0
    },
    state: {
      type: String,
      default: "hidden"
    },
    teamIcon: {
      type: String
    },
    numeric: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    info: {
      type: Array,
      default: []
    },
    position: Number
  },
  computed: {
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters("auth", ["client", "clientID"]),
    ...mapGetters(["game"]),
    isPresenter() {
      return User.isPresenter(this.$store.getters.user)
    },
    isVas() {
      return this.game?.importedFromBreadcrumb
    },
    isBreakdownVisible() {
      return !this.isPresenter && !this.isVas
    },
    positionSuffix() {
      switch (this.position) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    },
    positionText() {
      if (this.numeric) {
        if (this.position === 1) {
          return "1ST"
        } else if (this.position === 2) {
          return "2ND"
        } else if (this.position === 3) {
          return "3RD"
        } else {
          return this.position + "TH"
        }
      } else {
        if (this.position === 1) {
          return "FIRST"
        } else if (this.position === 2) {
          return "SECOND"
        } else if (this.position === 3) {
          return "THIRD"
        } else {
          return "TH"
        }
      }
    },
    total() {
      return {
        title: "total",
        points: this.teamTotal
      }
    },
    scores() {
      if (
        this.client.escapeRoom ||
        this.isPresenter ||
        this.details === false
      ) {
        return [this.total]
      }

      return this.info
    }
  },
  watch: {
    state(val) {
      if (val === "results") {
        this.$el.scrollIntoView({
          block: "end",
          inline: "center",
          behavior: "smooth"
        })
      }
    }
  }
}
</script>
<style lang="scss">
.team-specifics-card {
  $block: &;
  width: 100%;
  line-height: 1;
  perspective: 2000px;

  .card-flip-transition-enter-active,
  .card-flip-transition-leave-active {
    transition: transform ease 0.3s;
    transform: rotateX(0deg);
  }
  .card-flip-transition-enter {
    transform: rotateX(90deg);
  }
  .card-flip-transition-leave-to {
    transform: rotateX(-90deg);
  }

  .team-name-wrap {
    text-align: center;
    font-size: 1.3rem;
    background-color: currentColor;
    color: $primary_accent_color;
    margin: 5px;
    padding: 0.5rem;
    border-radius: 4px;
    .team-name {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }
    .team-icon {
      display: inline-block;
      vertical-align: middle;
      color: currentColor;
      /* color: $color-white; */
      font-size: 26px;
      margin-right: 0.25rem;
    }
  }

  &__card {
    border-radius: 20px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.32);
    border: 1px solid rgba(255, 255, 255, 0.3);

    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: center;
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
  }

  &__front-side {
    position: relative;
    color: $color-white;

    #{$block}--active & {
      background-image: radial-gradient(
        137.87% 137.87% at 49.24% 9.52%,
        #6e69d1 0%,
        #080b52 100%
      );
    }
  }

  &__medal {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__score {
    padding: 16px;

    &--presenter {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__score-list {
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 1.3vh;
    padding-bottom: 1.3vh;

    &:last-child {
      margin-top: 1.6vh;
      border-top: 1px solid rgba(255, 255, 255, 0.11);
      padding-top: 2.8vh;
      padding-bottom: 4px;

      #{$block}__score-title {
        font-size: 3vh;
      }

      #{$block}__score-points {
        font-size: 2.5vh;
      }
    }
  }

  &__score-title {
    font-size: 2.1vh;
    padding-left: 16px;
    font-family: Right Grotesk, sans-serif;
  }

  &__score-points {
    padding-right: 16px;
    text-align: right;
    min-width: 23%;
    font-size: 1.6vh;
    color: #b6d6ff;
  }

  &__back-side-text {
    margin-top: 60px;
    user-select: none;
    position: relative;
    font-size: 5rem;
    text-align: center;
    font-weight: bold;
    font-family: Right Grotesk, sans-serif;
    color: $color-white;
  }

  &__back-side-medal {
    height: 80px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  &__score-presenter {
    font-family: Right Grotesk, sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2;
  }

  &__presenter-title {
    font-size: 40px;
    text-transform: uppercase;
  }

  &__presenter-points {
    font-size: 52px;
    color: #b6d6ff;
  }
}
</style>
