<template>
  <div class="team-fact-match-card">
    <transition name="card-flip-transition" mode="out-in">
      <v-flex
        :key="state"
        d-flex
        class="card-front-side card"
        v-if="state === 'results'"
      >
        <v-layout column style="justify-content: center">
          <v-flex shrink pa-3>
            <span class="team-name team-name--block-ellipsis">{{
              teamName
            }}</span>
          </v-flex>
          <v-flex pa-3 shrink class="team-score">
            <span class="team-score-number">{{ pts }}</span
            ><span class="team-score-pts">pts</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex :key="state" d-flex class="card-back-side card" v-else>
        <v-layout column style="justify-content: center">
          <v-flex pa-3 shrink>
            <span
              class="team-name team-name--block-ellipsis team-name--card-back-side contrast-text-color"
              >{{ teamName }}</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TeamFactMatchCard",
  props: {
    teamName: {
      type: String,
      default: "Error"
    },
    state: {
      type: String,
      default: "hidden"
    },
    teamIcon: {
      type: String,
      default: "radio_button_unchecked"
    },
    pts: {
      type: Number,
      default: 0
    }
  },
  watch: {
    state(val) {
      if (val === "results") {
        this.$el.scrollIntoView({
          block: "end",
          inline: "center",
          behavior: "smooth"
        })
      }
    }
  }
}
</script>
<style lang="scss">
.team-fact-match-card {
  width: 100%;
  line-height: 1;
  perspective: 2000px;
  text-align: center;

  .card {
    height: 100%;
  }

  .card-flip-transition-enter-active,
  .card-flip-transition-leave-active {
    transition: transform ease 0.3s;
    transform: rotateX(0deg);
  }
  .card-flip-transition-enter {
    transform: rotateX(90deg);
  }
  .card-flip-transition-leave-to {
    transform: rotateX(-90deg);
  }
  .card {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
  }
  .card-front-side {
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #6e69d1 0%,
      #080b52 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.32);
    border-radius: 20px;
    color: $color-white;
    position: relative;
    .team-icon,
    .team-name {
      color: inherit;
    }
  }
  .card-back-side {
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.32);
    border-radius: 20px;
    color: $primary_accent_color;
    position: relative;
    .team-icon,
    .team-name {
      color: inherit;
    }
  }
  .team-icon {
    font-size: 2em;
  }
  .team-name {
    font-family: "Right Grotesk", san-serif;
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;

    &--block-ellipsis {
      max-height: 100px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
    }

    &--card-back-side {
      max-height: 122px;
      -webkit-line-clamp: 2;
    }
  }
  .team-score {
    font-size: 2em;
  }
  .team-score-number {
    display: inline-block;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    color: #00e599;
  }
  .team-score-pts {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    color: #00e599;
  }
}
</style>
