var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "team-specifics-card",
      class: { "team-specifics-card--active": _vm.current },
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "card-flip-transition", mode: "out-in" } },
        [
          _vm.state === "results"
            ? _c(
                "div",
                {
                  key: _vm.state,
                  staticClass:
                    "team-specifics-card__front-side team-specifics-card__card",
                },
                [
                  _c(
                    "div",
                    { staticClass: "team-specifics-card__medal" },
                    [
                      _c("Medal", { attrs: { variant: _vm.position } }, [
                        _vm._v(
                          " " + _vm._s(_vm.position + _vm.positionSuffix) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  !_vm.client.escapeRoom
                    ? _c(
                        "div",
                        {
                          staticClass: "team-specifics-card__score",
                          class: {
                            "team-specifics-card__score--presenter":
                              _vm.isPresenter,
                          },
                        },
                        [
                          _vm.isBreakdownVisible
                            ? _vm._l(_vm.scores, function (score, idx) {
                                return _c(
                                  "v-layout",
                                  {
                                    key: "team-spec-card-" + idx,
                                    staticClass:
                                      "team-specifics-card__score-list",
                                    attrs: { row: "" },
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "team-specifics-card__score-title",
                                        attrs: { grow: "" },
                                      },
                                      [_vm._v(" " + _vm._s(score.title) + " ")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "team-specifics-card__score-points",
                                        attrs: { shrink: "" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.prefix) +
                                            _vm._s(score.points) +
                                            " " +
                                            _vm._s(_vm.postfix) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              })
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "team-specifics-card__score-presenter",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "team-specifics-card__presenter-title",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.total.title) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "team-specifics-card__presenter-points",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.prefix) +
                                            _vm._s(_vm.total.points) +
                                            " " +
                                            _vm._s(_vm.postfix) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              )
            : _c(
                "div",
                {
                  key: _vm.state,
                  staticClass:
                    "team-specifics-card__back-side team-specifics-card__card",
                  attrs: { "d-flex": "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "team-specifics-card__back-side-medal" },
                    [_c("Medal", { attrs: { variant: _vm.position } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "team-specifics-card__back-side-text" },
                    [_c("div", [_vm._v(_vm._s(_vm.positionText))])]
                  ),
                ]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }